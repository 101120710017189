import React from 'react'
import Graylogo from '../img/sundarban/logo.png';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer id="footer">
        <div className="container" style={{paddingBottom:'0px'}}>
          {/* <form
            method="post"
            className="newsletter-form"
          >
            <fieldset>
              <div className="input-holder">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  name="subscriber_email"
                  id="subscriber_email"
                />
                <input type="submit" value="GO" />
              </div>
              <span className="info" id="subscribe_message"
                >To receive news, updates and tour packages via email.</span
              >
            </fieldset>
          </form> */}
          <div className="row footer-holder">
            <nav className="col-sm-4 footer-nav active">
              <img
                className="gray-logo"
                style={{height:'131px'}}
                src={Graylogo}
                alt=""
            />
            <p style={{color:'#a7a7a7',fontSize: '15px',
    paddingTop: '12px'}}>
            Welcome to The Sundarban your gateway to one of the world's most mesmerizing natural wonders. 
            Nestled in the heart of the Sundarbans.
            </p>
            </nav>
            <nav className="col-sm-2 footer-nav">
            <h3>Quick Links</h3>
              <ul className="slide">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About Us</Link></li>
                <li><Link href="/package">Tours Packages</Link></li>
                <li><Link to="/contact-us">Contact</Link></li>
              </ul>
            </nav>
            <nav className="col-sm-2 footer-nav">
              <h3>Explore</h3>
              <ul className="slide">
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                <li><Link to="/cancellation">Cancellation & Refund</Link></li>
                <li><Link to="/conditions">Terms & Conditions</Link></li>
              </ul>
            </nav>
            <nav className="col-sm-4 footer-nav">
              <h3>Address</h3>
              <ul className="slide">
                <li><a href="#">Village: Rangabelia , Post Office: Rangabelia ,
                 Dist: South 24 Pgs , Pin - 743370, West Bengal, India.</a></li>
                <li><a href="#">Regd. Address:- Rangabelia, South 24 Paraganas, West Bengal-743370</a></li>
              </ul>
            </nav>
            
            
          </div>
          <hr />
          <p style={{color:'white'}}>© 2024 The Sundarban All right reserved</p>
        </div>
     
      </footer>
  )
}

export default Footer