import React from 'react'

import './main.css';
import './bootstrap.css'
import './animate.css'

import Footer from './Footer'
import AdoutData2 from './AdoutData2';
import Header2 from './Header2';
function Conditions() {
  return (
    <div className='default-page'>
            <div id="wrapper">
                <div className="page-wrapper">
                    <Header2 />
                    <section className="">
                        <div className='bgimg1'>
                            <div className='about-box'>
                                <h3 className='text-center' style={{ color: 'white' }}> 
                                Terms & Conditions
                                </h3>
                            </div>
                        </div>
                    </section>
                    <div className='container'>
                        <div className='row '>
                            <div className='col-sm-12'>
                                <h3>Terms & Conditions</h3>
                                <p>
                                Welcome to The Sundarban website. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before using our website.
                                </p>
                                <h4>*1. Acceptance of Terms:*</h4>
                                <p>
                                    By accessing or using The Sundarban website, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you may not access or use our website.
                                </p>
                                <h4>*2. Use of Website:*</h4>
                                <p>
                                    You agree to use The Sundarban website for lawful purposes only and in a manner consistent with all applicable laws and regulations. You may not use our website in any way that could damage, disable, overburden, or impair the website or interfere with any other party's use and enjoyment of the website.
                                </p>
                                <h4>*3. Intellectual Property:*</h4>
                                <p>
                                All content included on The Sundarban website, such as text, graphics, logos, images, videos, and software, is the property of The Sundarban or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not modify, reproduce, distribute, display, or create derivative works of any content on the website without prior written permission from The Sundarban.
                                </p>
                                <h4>4. Booking and Payment:*</h4>
                                <p>
                                Booking a tour through The Sundarban website is subject to availability and confirmation. Prices listed on the website are in the specified currency and may be subject to additional taxes or fees. Payment must be made in full at the time of booking unless otherwise specified. We accept payment by credit/debit card or other payment methods specified on the website.
                                </p>
                                <h4>*5. Cancellation and Refund:*</h4>
                                <p>
                                Please refer to our Cancellation & Refund Policy for information regarding cancellations, refunds, and modifications to bookings.
                                </p>
                                <h4>*6. Limitation of Liability:*</h4>
                                <p>
                                The Sundarban website is provided on an "as is" and "as available" basis without warranties of any kind, either express or implied. We do not guarantee that the website will be uninterrupted or error-free, or that defects will be corrected. In no event shall The Sundarban be liable for any 
                                direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of or inability to use the website.
                                </p>
                                <h4>*7. Links to Third-Party Websites:*</h4>
                                <p>
                                The Sundarban website may contain links to third-party websites that are not owned or controlled by The Sundarban. We are not responsible for the content, privacy policies, or practices of any third-party websites. Your use of third-party websites is at your own risk.
                                </p>
                                <h4>*8. Changes to Terms:*</h4>
                                <p>
                                The Sundarban reserves the right to update or modify these terms and conditions at any time without prior notice. Your continued use of the website after any changes indicates your acceptance of the revised terms.
                                </p>
                                <h4>*9. Governing Law:*</h4>
                                <p>These terms and conditions shall be governed by and construed in accordance with the laws of [jurisdiction], without regard to its conflict of law provisions.</p>
                                <h4>*10. Contact Us:*</h4>
                                <p>
                                If you have any questions or concerns regarding these terms and conditions, please contact us at https://thesundarban.com/contact-us
By using The Sundarban website, you agree to these terms and conditions. If you do not agree with any part of these terms, you may not access or use our website.

                                </p>
                        </div>
                    </div>
                     </div>
                     </div>
                <Footer />
           
        </div>
        </div>
  )
}

export default Conditions