import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "./featurs/loginSlice";
import packageSlice from "./featurs/packageSlice";

const store = configureStore({
    reducer:{
        login: loginSlice,
        package: packageSlice
    }
})

export default store;