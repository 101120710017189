import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


import './main.css';
import './bootstrap.css'
import './animate.css'

import Header from './Header';

import banner1 from '../img/sundarban/SundarbanImg/img5.jpg';// require().default

import gallery1 from '../img/sundarban/galaery.jpg';
import gallery2 from '../img/sundarban/galary2.jpg';
import gallery3 from '../img/sundarban/galary3.jpg';

import list1 from "../img/listing/img-01.jpg";
import list2 from "../img/listing/img-02.jpg";
import list3 from "../img/listing/img-03.jpg";
import list4 from "../img/listing/img-04.jpg";
import list5 from "../img/listing/img-05.jpg";
import list6 from "../img/listing/img-06.jpg";

import imgA1 from "../img/img-01.jpg";
import imgA2 from "../img/listing/img-07.jpg";
import imgA3 from "../img/listing/img-08.jpg";
import imgA4 from "../img/listing/img-09.jpg";
import imgA5 from "../img/listing/img-13.jpg";
import Footer from './Footer';
import "react-image-gallery/styles/css/image-gallery.css";

import galImg1 from "../img/sundarban/Sundarbans_web.jpg";
import galImg2 from "../img/sundarban/Sunderban-Delta.png";
import galImg3 from "../img/sundarban/sundarban2.jpg";
import galImg4 from "../img/sundarban/sundarban3.jpg";
import galImg5 from "../img/sundarban/sundareban4.jpg";
import PackageList from './PackageList';
import { Link, useNavigate } from 'react-router-dom';
import Header2 from './Header2';


function Home() {
    const [packaheData, setpackaheData] = useState()
    const navigate = useNavigate();
    const [visibleElements, setVisibleElements] = useState([]);

    const toggleVisibility = (id) => {
    if (visibleElements.includes(id)) {
      setVisibleElements(visibleElements.filter((elementId) => elementId !== id));
    } else {
      setVisibleElements([...visibleElements, id]);
    }
  };
    useEffect(()=>{
        fetch('https://nuggettrap.com/api/turist/listpackage.php')
        .then((res)=>res.json())
        .then((result)=>{
         console.log(result)
         setpackaheData(result.result)
        });
         
     },[])
    return (
        <div className='default-page'>
            <div id="wrapper">
                <div className="page-wrapper">
                    <Header2 />
                    {/* <Header /> */}
                    {/* <section>
                        <Carousel>
                            <div>
                                <img src={banner1} />
                                <p className="legend">
                                    <a href="#" class="btn btn-banner">Read More</a>
                                </p>

                            </div>
                            <div>
                                <img src={galImg4} />
                                <a href="#" class="btn btn-banner">Read More</a>
                            </div>
                            <div>
                                <img src={galImg2} />
                                <a href="#" class="btn btn-banner">Read More</a>
                            </div>
                            <div>
                                <img src={galImg3} />
                                <a href="#" class="btn btn-banner">Read More</a>
                            </div>
                            <div>
                                <img src={galImg5} />
                                <a href="#" class="btn btn-banner">Read More</a>
                            </div>
                        </Carousel>
                    </section> */}
                     <section className="banner banner-home">
                        <div className="banner-image">
                            <div className="bg-stretch">
                                <img
                                    src={banner1}
                                    height="1280"
                                    width="1920"
                                    alt="image desciption"
                                />
                            </div>
                            <div className="banner-text banner-caption-s1">
                                <div className="center-text">
                                    <h1 style={{color:'#ff8b00'}}>
                                        <span>The Sundarban: Where Every Journey is a Tale of Nature's Majesty.</span> 
                                        </h1>
                                    
                                    <div className="btn-banner-holder">
                                        <Link to="/package" className="btn btn-banner btn-readmore">Book now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <main id="main">
                        <section className=" bg-white">
                            <div className="container">
                                <header className="content-heading">
                                    <h2 className="main-heading">THE ADVENTURE</h2>
                                    <span className="main-subtitle"
                                    >Mastering the art of perfect adventure for 10+ years in the
                                        wild.</span
                                    >
                                    <div className="seperator"></div>
                                </header>
                                <div className="adventure-holder gallery-home-holder">
                                    <div className="row">
                                        <div className="col-md-6 img-block">
                                            <ul className="gallery-list gallery-with-icon">
                                                <li>
                                                    <a href="#" className="thumbnail">
                                                        <img
                                                            src={gallery1}
                                                            style={{ height: '175px', width: '165px' }}
                                                            alt="image description"
                                                        />
                                                        <span className="hover icon-water"></span>
                                                        <span className="info">Dance</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="thumbnail">
                                                        <img
                                                            src={gallery2}
                                                            style={{ height: '175px', width: '165px' }}
                                                            alt="image description"
                                                        />
                                                        <span className="hover icon-asia"></span>
                                                        <span className="info">Food</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="thumbnail">
                                                        <img
                                                            src={gallery3}
                                                            style={{ height: '175px', width: '165px' }}
                                                            alt="image description"
                                                        />
                                                        <span className="hover icon-arctic"></span>
                                                        <span className="info">Boat</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="thumbnail">
                                                        <img
                                                            src={galImg3}
                                                            style={{ height: '175px', width: '165px' }}
                                                            alt="image description"
                                                        />
                                                        <span className="hover icon-water"></span>
                                                        <span className="info">Forest</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="thumbnail">
                                                        <img
                                                            src={galImg4}
                                                            style={{ height: '175px', width: '165px' }}
                                                            alt="image description"
                                                        />
                                                        <span className="hover icon-asia"></span>
                                                        <span className="info">River</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="thumbnail">
                                                        <img
                                                            src={galImg5}
                                                            style={{ height: '175px', width: '165px' }}
                                                            alt="image description"
                                                        />
                                                        <span className="hover icon-arctic"></span>
                                                        <span className="info">Animal</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 ">
                                            <div className="">
                                                <h2 className="intro-heading">Living a Life of Adventure</h2>
                                                <p className="text-1">
                                                Welcome to The Sundarban - your gateway to one of the world's most mesmerizing natural wonders. Nestled in the heart of the Sundarbans, the largest mangrove forest on the planet, our agency is dedicated to providing unparalleled experiences that immerse you in the beauty and magic of this UNESCO World Heritage Site.
                                                </p>
                                                <Link
                                                    to="/package"
                                                    className="btn btn-info-sub btn-md btn-block btn-shadow radius"
                                                >Read  More
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <aside className="special-block">
                            <div className="container">
                                <p className="special-text">
                                    Get special discount on select treks, this week.
                                    <strong>Call <a href="tel:">+91 9734818702</a></strong>
                                </p>
                            </div>
                        </aside>
                        <div className="content-block content-spacing">
                            <div className="container">
                                <header className="content-heading">
                                    <h2 className="main-heading">POPULAR TOURS</h2>
                                    <span className="main-subtitle"
                                    >We have a unique way of meeting your adventurous
                                        expectations!</span
                                    >
                                    <div className="seperator"></div>
                                </header>
                                <div className="content-holder">
                                    {/* <PackageList /> */}
                                    {packaheData && packaheData.length >0 &&
                                        <>
                                        {packaheData.map((list,index)=>(
                                             <div className="col-sm-3 article" key={list.id}>
                                             <div className="img-wrap1">
                                                 <img
                                                     style={{height:'300px',width:'300px',}}
                                                     src={list.img_link}
                                                     alt="image description"
                                                 />
                                                 <h5 style={{height:'20'}}>{list.name} </h5>
                                                 <p className='short-text'
                                                 style={{ display: visibleElements.includes(list.id) ? 'none' : 'block' }}
                                                 > 
                                                    {list.mini_desc}
                                                 </p>
                                                 <p className='long-text' 
                                                 id={list.id}
                                                 style={{ display: visibleElements.includes(list.id) ? 'block' : 'none' }}
                                               > 
                                                    {list.mini_desc}
                                                 </p>
                                                 <span onClick={(e)=>{toggleVisibility(list.id)}}
                                                 style={{cursor:'pointer'}}
                                                 >
                                                    See {visibleElements.includes(list.id) ? (<span>less</span>):(<span> more</span>) }
                                                    
                                                    ..</span>

                                                 <button type='button' className='btn btn-warning btn-block'
                                                 onClick={(e)=>{navigate(`/booking/${list.id}`) }}>
                                                     Book now
                                                 </button>
                                             </div>
                                         </div>
                                        ))}
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        

                        {/* <div className="browse-block">
                            <div className="browse-destination column">
                                <a href="#"><span>BROWSE BY DESTINATION</span></a>
                            </div>
                            <div className="browse-adventures column">
                                <a href="#"><span>BROWSE BY ADVENTURES</span></a>
                            </div>
                        </div> */}
                    

                    </main>
                </div>
                <Footer />
            </div>

        </div>

    )
}

export default Home