import React, { useEffect, useState } from 'react'

import './main.css';
import './bootstrap.css'
import './animate.css'

import Header from './Header'
import Footer from './Footer'

import banner1 from '../img/sundarban/Sundari-Tree-in-Sundarban.jpg';
import banner2 from '../img/sundarban/sundareban4.jpg';
import banner3 from '../img/sundarban/sundarban3.jpg';
import icon1 from '../img/icons/ico01.svg';
import icon2 from '../img/icons/ico02.svg';
import icon3 from '../img/icons/ico03.svg';

import { useNavigate } from 'react-router-dom';

import { useSelector,useDispatch } from 'react-redux';
import { fetchPackage } from '../app/featurs/packageSlice';
import Header2 from './Header2';

function Package() {
    const dispatch = useDispatch();
    const {error,loading,packageName,packagePrice,packageMiniDesc,packageDesc,listPackage } = useSelector((state)=>state.package)
    
    const navigate = useNavigate();
    const [packaheData, setpackaheData] = useState()
    const [visibleElements, setVisibleElements] = useState([]);

    const toggleVisibility = (id) => {
    if (visibleElements.includes(id)) {
      setVisibleElements(visibleElements.filter((elementId) => elementId !== id));
    } else {
      setVisibleElements([...visibleElements, id]);
    }
  };
    useEffect(()=>{
       /// dispatch(fetchPackage())
       const res1 = fetch('https://nuggettrap.com/api/turist/listpackage.php')
       .then((res)=>res.json())
       .then((result)=>{
        console.log(result)
        setpackaheData(result.result)
       })
       ;

        setTimeout(() => {
          //  console.log(res1)
           /// setpackaheData(listPackage)
        }, 3500);
        
    },[])
    return (
        <div className='default-page'>
            <div id="wrapper">
                <div className="page-wrapper">
                    <Header2 />
                    <section className="">
                        <div className='bgimg1'>
                            <div className='about-box'>
                                <h3 className='text-center' style={{ color: 'white' }}>Sundarban Packages</h3>
                            </div>
                        </div>
                    </section>

                    <main id="main">
                        <div className="content-block bg-white content-center" style={{ paddingTop: 0 }}>
                            <div className="container">
                                <header className="content-heading">
                                    <h2 className="main-heading">THE ADVENTURE</h2>
                                    <strong className="main-subtitle"> Mastering the art of perfect adventure for 10+ years in the
                                        wild.</strong>
                                    <div className="seperator"></div>
                                </header>
                                <div className="row">
                                    <br />
                                    {packaheData && packaheData.length >0 &&
                                        <>
                                        {packaheData.map((list,index)=>(
                                             <div className="col-sm-3 article" key={list.id}>
                                             <div className="img-wrap1">
                                                 <img
                                                     style={{height:'300px',width:'300px',}}
                                                     src={list.img_link}
                                                     alt="image description"
                                                 />
                                                 <h5 style={{height:'20'}}>{list.name} </h5>
                                                 <p className='short-text'
                                                 style={{ display: visibleElements.includes(list.id) ? 'none' : 'block' }}
                                                 > 
                                                    {list.mini_desc}
                                                 </p>
                                                 <p className='long-text' 
                                                 id={list.id}
                                                 style={{ display: visibleElements.includes(list.id) ? 'block' : 'none' }}
                                               > 
                                                    {list.mini_desc}
                                                 </p>

                                                 <span onClick={(e)=>{toggleVisibility(list.id)}}
                                                 style={{cursor:'pointer'}}
                                                 >
                                                    See {visibleElements.includes(list.id) ? (<span>less</span>):(<span> more</span>) }
                                                    
                                                    ..</span>

                                                 <button type='button' className='btn btn-warning btn-block'
                                                 onClick={(e)=>{navigate(`/booking/${list.id}`) }}>
                                                     Book now
                                                 </button>
                                             </div>
                                         </div>
                                        ))}
                                        </>
                                    }
                                   
                                    
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Package