import React, { useEffect } from 'react'
import { FaMarker, FaStar } from 'react-icons/fa'
import { MdCatchingPokemon, MdCurrencyRupee, MdFlight, MdLocationOn, MdLockClock, MdSchedule } from 'react-icons/md'

function PackageDetails2(props) {
    useEffect(()=>{
        console.log(props)
    },[])
  return (
    <div className='container'>
        <div className='row'>
            <div className='col-sm-6'>
                <h2 style={{color:'#6e2009'}}>{props.propData.name} </h2>
                <h4 style={{color:'#6e2009'}}><MdLocationOn size={20} /> Sundarban </h4>
            </div>
            <div className='col-sm-6'>
                <div className='row left-box'>
                    <div className='col-sm-4'>
                        <MdCurrencyRupee /> from {props.propData.price}
                    </div>
                    <div className='col-sm-4'>
                        <MdSchedule /> Duration

                    </div>
                    <div className='col-sm-4'>
                        <MdFlight /> Tour Type
                    </div>
                </div>
            </div>
        </div>
        <div className='row padding-top-20'>
            <div className='col-sm-12'>
            <div className="star-rating">
                <span><FaStar color="#6e2009" /> </span>
                <span><FaStar color="#6e2009"/></span>
                <span><FaStar color="#6e2009"/></span>
                <span><FaStar color="#6e2009"/></span>
                <span className="disable">
                    <FaStar color="#6e2009"/>
                </span> 
                <span className='def-color'>&nbsp; 3.33 by 3 reviews</span>
            </div>
            <h2>Overview</h2>
            </div>
        </div>
        <div className='row'>
            <div className='col-sm-8 details-div'>
                <h3 className='def-color'>DETAILED DAYWISE ITINERARY</h3>
                <div dangerouslySetInnerHTML={{ __html: props.propData.details }} />



                <h3 className='def-color'>INCLUDES</h3>
                <ul>
                    <li> Transportation From Kolkata By Ac Vehicle Sharing Basis.</li>
                    <li> Accommodation Deluxe Room Ac / Non Ac</li>
                    <li> Mechanised well maintained Boat with beds and European Toilet.</li>
                    <li> All major meals like Breakfast, Lunch, Evening tea Snacks, Dinner bed tea.</li>
                    <li> Evening Cultural Programme.</li>
                    <li> Jungle entry fees and guide charges.</li>
                    <li> Boat permits and Still Camera permissions.</li>
                    <li> Camp Fire (Winter Season Only).</li>
                </ul>
                <br />
                <h3 className='def-color'>EXCLUDES</h3>
                <ul>
                    <li> Any Hard or Aerated Drinks.</li>
                    <li> Video camera charges.</li>
                    <li> Any miscellaneous expenses incurred by the guests.</li>
                    <li> Coolie Charge.</li>
                    <li> Non Indian guests will have to pay separately for the Permit fees inside the Tiger Reserve.</li>
                </ul>
                <br />
                <h3 className='def-color'>MENU DETAILS</h3>
                <p>
               <b> Breakfast</b> <br />

               - Assorted fresh fruits (seasonal) <br />
               - Toasted bread with butter and jam <br />
               - Scrambled eggs or omelette <br />
               - Aloo paratha/Luchi (potato-stuffed flatbread) with yogurt or pickle <br />
               - Tea, coffee, or fruit juice
                <br />
                <b>Lunch</b> <br />
                - Steamed rice <br />
                - Mixed vegetable curry <br />
                - Fish curry (local fish like Hilsa or Pomfret) <br />
                - Chicken curry or vegetarian paneer curry or Mutton Curry <br />
                - Dal (lentil soup) <br />
                - Salad (cucumber, tomato, onion) <br />
                - Papad (thin, crisp disc-shaped food typically made from lentil flour) <br />
                - Assorted sweets (Rosogolla, Sandesh) <br />

                <b>*Evening Snacks (During Sunset Cruise):*</b> <br />
                - Pakoras (deep-fried fritters) - assorted vegetables or fish <br />
                - Samosas (fried pastry with savory filling) <br />
                - Tea, coffee, or soft drinks <br />

                <b>Dinner</b> <br />
                - Roti (Indian flatbread) or naan <br />
                - Vegetable pulao (fragrant rice dish with mixed vegetables) <br />
                - Fish fry or grilled fish <br />
                - Chicken tikka or paneer tikka (grilled chicken or paneer marinated in spices) <br />
                - Mixed vegetable stir-fry <br />
                - Raita (yogurt side dish with cucumber, onion, and tomato) <br />
                - Dessert: Firni (rice pudding) or fruit salad <br />

               <b> *Note:* </b> <br />
- The menu can vary depending on dietary preferences, availability of ingredients, and the tour operator's offerings.
<br />- Seafood, especially local varieties like Hilsa fish, is often a highlight of Sundarbans cuisine.
<br />- Vegetarian options can be provided upon request.
<br />- It's essential to consider the availability of fresh ingredients and the storage capabilities on the boat during the tour.

                </p>
            </div>
            <div className='col-sm-4'></div>
        </div>
    </div>
  )
}

export default PackageDetails2