import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
function TestEditor(props) {
    const [value, setValue] = useState('');
    useEffect(()=>{
        props.testVal(value);
    },[value])
  return (
    <div>
        <ReactQuill theme="snow" value={value} onChange={setValue} style={{height:'150px'}} />
    </div>
  )
}

export default TestEditor