import React from 'react'
import Header from './Header'
import Footer from './Footer'

import tiger from '../img/sundarban/logo.png'

import { Link } from 'react-router-dom'
import { FaEye, FaLockOpen, FaUser } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { setUsername,setPassword,setError } from '../app/featurs/loginSlice'
import { useNavigate } from 'react-router-dom'
import Header2 from './Header2'
import ReCAPTCHA from "react-google-recaptcha";



function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { username,password,error } = useSelector((state)=>state.login);
    
    const handalSubmit = ()=>{
        if(! username.trim()) return dispatch(setError('Please enter Username!'));
        if(! password.trim()) return dispatch(setError('Please enter password'));
        dispatch(setError(null));
        if((username=='admin')&& (password=='123')){
            navigate('/admin/dashboard')
        }
       // alert('all ok');
        

    }
    return (
        <div className='default-page'>
            <div id="wrapper">
                <div className="page-wrapper">
                    <Header2 />
                    <section className="">
                        <div className='bgimg1' style={{height:'700px'}}>
                            <div className='about-box'>
                                <h3 className='text-center' style={{ color: 'white' }}>
                                    Home / Login </h3>
                                    
                                    <div className='box-login'>
                                        <div className='text-center'>
                                            <img src={tiger} style={{height:'70px'}} alt='sundarban' />
                                        </div>
                                    <form method='post'>
                                        <div>
                                            {error && <p className='text-danger'>{ error}</p> }
                                        </div>
                                        <div className='form-group input-icons'>
                                            <FaUser color='white' size={25} />
                                            <input type='text' className='form-control' placeholder='Username' 
                                            onChange={(e)=>{dispatch(setUsername(e.target.value)) }}/>
                                        </div>
                                        <div className='form-group input-icons'>
                                            <FaEye color='white' size={25} />
                                            <input type='password' className='form-control' placeholder='********' 
                                            onChange={(e)=>{dispatch(setPassword(e.target.value)) }}/>
                                        </div>
                                        <div className='form-group'>
                                        <ReCAPTCHA
                                                sitekey="6LcHwQUpAAAAAG_RZeMJs-oON-mGHGp_2bplJN-O"
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <button type='button' className='btn btn-block btn-warning'
                                            onClick={handalSubmit}>
                                                Login &nbsp;
                                                <FaLockOpen size={20} />
                                            </button>
                                        </div>
                                        
                                        <div className=''>
                                            <p className='text-white text-center'>
                                            <Link className='text-white underline'>Forgot password ?</Link>
                                            </p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            
                        </div>
                    </section>

                    <main id="main">
                        <div class="content bg-white">
                            <div class="container">
                               <div className='row'>
                                <div className='col-sm-6'></div>
                                
                               </div>
                            </div>
                        </div>

                    </main>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Login