import Home from './view/Home';
import About from './view/About';
import { Route, Routes } from 'react-router-dom';
import Contact from './view/Contact';
import Package from './view/Package';
import Booking from './view/Booking';
import Login from './view/Login';
import Dashboard from './view/admin/Dashboard';
import AddPackage from './view/admin/AddPackage';
// import Dashboard from './view/admin/Dashboard';
// import AddPackage from './view/admin/AddPackage';
// import 'bootstrap/dist/css/bootstrap.min.css';
import CookieConsent, { Cookies } from "react-cookie-consent";
import Privacypolicy from './view/Privacypolicy';
import Cancellation from './view/Cancellation';
import Conditions from './view/Conditions';


function App() {
  return (
    <div >
      <CookieConsent enableDeclineButton flipButtons
        style={{ padding: '40px 5px' }}
        location="bottom"
        buttonText="I accept"

        containerClasses="alert alert-warning col-lg-12"
        contentClasses="text-capitalize"
        expires={150}
      >
        We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept All”, you consent to the use of ALL the cookies. However, you may visit "Cookie Settings" to provide a controlled consent
      </CookieConsent>
      <Routes>
        <Route path='/' >
          <Route path='/' element={<Home />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='/contact-us' element={<Contact />}></Route>
          <Route path='/package' element={<Package />}></Route>
          <Route path='/booking/:id' element={<Booking />}></Route>
          <Route path='/login' element={<Login />}></Route>
          <Route path='/privacy-policy' element={<Privacypolicy />}></Route>
          <Route path='/cancellation' element={<Cancellation />}></Route>
          <Route path='/conditions' element={<Conditions />}></Route>
          <Route path='/whatsapp' element={()=>{alert('test') } }></Route>
        </Route>
        <Route path='/admin'>
          <Route path='/admin/dashboard' element={<Dashboard />} ></Route>
          <Route path='/admin/add-package' element={<AddPackage />} ></Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
