import React, { useEffect, useState } from 'react'

import './main.css';
import './bootstrap.css'
import './animate.css'

import Header from './Header'
import Footer from './Footer'

import banner1 from '../img/banner/img-06.jpg';
import PackageDetails2 from './PackageDetails2';
import { json, useParams } from 'react-router-dom';

import { getPackage } from '../app/featurs/packageSlice';
import { useSelector,useDispatch } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import Header2 from './Header2';


function Booking(props) {
    const [fdata,setFdata] = useState({page:'booking'});
    const [subed, setSubed ] =useState(false)
    const dispatch = useDispatch();
    const {singledata} = useSelector((state)=>state.package)
    const { id } = useParams();
    const [dataPackg, setPkgData] = useState(null);
    const  handalSubmit = (e) =>{
        e.preventDefault();
        fetch('https://nuggettrap.com/api/turist/contact.php',{
            method:'POST',
            body:JSON.stringify(fdata)
        })
        .then((e)=>e.json())
        .then((result)=>{
            setFdata({page:'contact',fname:'',lname:'',email:'',contact:'',presone:'',t_date:'' });
            setSubed(true)
        })
    }

    useEffect(()=>{
         fetch('https://nuggettrap.com/api/turist/package.php?id='+id)
         .then((res)=>res.json())
         .then((result)=>{
            setPkgData(result.result[0])
         })
        
    },[])
    return (
        <div className='default-page'>
            <div id="wrapper">
                <div className="page-wrapper">
                    <Header2 />
                    {/* <section className="">
                        <div className='bgimg1'>
                            <div className='about-box'>
                                <h3 className='text-center' style={{ color: 'white' }}>Home / Booking Details</h3>
                            </div>
                        </div>
                    </section> */}

                    <main id="main">
                        <div className="content-block bg-white content-center" >
                            <div className="container">
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        {dataPackg &&
                                        <img src={dataPackg.img_link} alt='' /> }
                                    </div>
                                    <div className='col-sm-6'>
                                        <form method='post' onSubmit={handalSubmit}>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <h3 className='text-center'>Booking </h3>
                                                <hr style={{borderTop:'1px solid black'}} />
                                                {subed &&
                                                <h4 className='text-success'>
                                                    Our representative will get in touch you soon.
                                                </h4>}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <label>First name</label>
                                                <input type='text' className='form-control'
                                                value={fdata.fname} 
                                                onChange={(e)=>{setFdata((prev)=>({...prev,fname:e.target.value}))}} />
                                            </div>
                                            <div className='col-sm-6'>
                                                <label> Last name</label>
                                                <input type='text' className='form-control'
                                                value={fdata.lname} 
                                                onChange={(e)=>{setFdata((prev)=>({...prev,lname:e.target.value}))}} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <label>Email</label>
                                                <input type='text' className='form-control' 
                                                value={fdata.email} 
                                                onChange={(e)=>{setFdata((prev)=>({...prev,email:e.target.value}))}}/>
                                            </div>
                                            <div className='col-sm-6'>
                                                <label>Contact </label>
                                                <input type='text' className='form-control' 
                                                value={fdata.contact} 
                                                onChange={(e)=>{setFdata((prev)=>({...prev,contact:e.target.value}))}}/>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <label>Number of persons </label>
                                                <input type='number' className='form-control' 
                                                value={fdata.presone} 
                                                onChange={(e)=>{setFdata((prev)=>({...prev,presone:e.target.value}))}}/>
                                            </div>
                                            <div className='col-sm-6'>
                                                <label>Date </label>
                                                <input type='date' className='form-control'
                                                value={fdata.t_date} 
                                                onChange={(e)=>{setFdata((prev)=>({...prev,t_date:e.target.value}))}} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <br />
                                            <ReCAPTCHA
                                                sitekey="6LcHwQUpAAAAAG_RZeMJs-oON-mGHGp_2bplJN-O"
                                            />
                                            </div>
                                            </div>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <label> </label>
                                                <br />
                                                <input type='submit' className='btn btn-block btn-success' value='Book now' />
                                            </div>
                                        </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                            {/*  style="position: fixed; top: 70px; width: 1303px;" */}
                            {/* <PackageDetails /> */}
                            {dataPackg &&
                            <>
                            <PackageDetails2 propData={dataPackg} />
                            </>
                            }
                            
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Booking