import React from 'react'
import Graylogo from '../img/sundarban/logo.png';
//import Graylogo from '../img/logos/logo-gray.svg';
import logo from '../img/logos/logo.svg';
import image1 from '../img/generic/img-01.jpg'
import { Link } from 'react-router-dom';

function Header() {
    return (
        <header id="header">
            <div className="container-fluid">
                <div className="logo">
                    <Link to="/">
                        <img className="normal" src={Graylogo} 
                        style={{height:'76px'}} alt="" />
                        <img
                            className="gray-logo"
                            src={Graylogo}
                            alt=""
                        />
                    </Link>
                </div>
                <nav className="navbar navbar-default">
                    <div className="navbar-header">
                        <button
                            type="button"
                            className="navbar-toggle nav-opener"
                            data-toggle="collapse"
                            data-target="#nav"
                        >
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                    </div>
                    <div className="collapse navbar-collapse" id="nav">
                        <ul className="nav navbar-nav">
                            <li className="dropdown">
                                <Link to="/">Home <b className="icon-angle-down"></b></Link>
                            </li>
                            <li className="dropdown">
                                <Link to="/about">About US <b className="icon-angle-down"></b></Link>
                            </li>
                            <li className="dropdown">
                                <Link to="/contact-us">Contact us <b className="icon-angle-down"></b></Link>
                            </li>
                            <li className="dropdown">
                                <Link to="/package">Packages <b className="icon-angle-down"></b></Link>
                            </li>
                            
                            <li className="dropdown">
                                <Link to="/login">Login <b className="icon-angle-down"></b></Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
            <form className="search-form" action="#">
                <fieldset>
                    <a href="#" className="search-opener hidden-md hidden-lg">
                        <span className="icon-search"></span>
                    </a>
                    <div className="search-wrap">
                        <a href="#" className="search-opener close">
                            <span className="icon-cross"></span>
                        </a>
                        <div className="trip-form trip-form-v2 trip-search-main">
                            <div className="trip-form-wrap">
                                <div className="holder">
                                    <label>Departing</label>
                                    <div className="select-holder">
                                        <div
                                            id="datepicker"
                                            className="input-group date"
                                            data-date-format="mm-dd-yyyy"
                                        >
                                            <input className="form-control" type="text" readonly />
                                            <span className="input-group-addon"
                                            ><i className="icon-drop"></i
                                            ></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="holder">
                                    <label>Returning</label>
                                    <div className="select-holder">
                                        <div
                                            id="datepicker1"
                                            className="input-group date"
                                            data-date-format="mm-dd-yyyy"
                                        >
                                            <input className="form-control" type="text" readonly />
                                            <span className="input-group-addon"
                                            ><i className="icon-drop"></i
                                            ></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="holder">
                                    <label for="select-region">Select Region</label>
                                    <div className="select-holder">
                                        <select
                                            className="trip-select trip-select-v2 region"
                                            name="region"
                                            id="select-region"
                                        >
                                            <option value="select">Africa</option>
                                            <option value="select">Arctic</option>
                                            <option value="select">Asia</option>
                                            <option value="select">Europe</option>
                                            <option value="select">Oceanaia</option>
                                            <option value="select">Polar</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="holder">
                                    <label for="select-activity">Select Activity</label>
                                    <div className="select-holder">
                                        <select
                                            className="trip-select trip-select-v2 acitvity"
                                            name="activity"
                                            id="select-activity"
                                        >
                                            <option value="Holiday Type">Holiday Type</option>
                                            <option value="Holiday Type">Beach Holidays</option>
                                            <option value="Holiday Type">Weekend Trips</option>
                                            <option value="Holiday Type">Summer and Sun</option>
                                            <option value="Holiday Type">Water Sports</option>
                                            <option value="Holiday Type">Scuba Diving</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="holder">
                                    <label for="price-range">Price Range</label>
                                    <div className="select-holder">
                                        <select
                                            className="trip-select trip-select-v2 price"
                                            name="activity"
                                            id="price-range"
                                        >
                                            <option value="Price Range">Price Range</option>
                                            <option value="Price Range">$1 - $499</option>
                                            <option value="Price Range">$500 - $999</option>
                                            <option value="Price Range">$1000 - $1499</option>
                                            <option value="Price Range">$1500 - $2999</option>
                                            <option value="Price Range">$3000+</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="holder">
                                    <button className="btn btn-trip btn-trip-v2" type="submit">
                                        Find Tours
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </header>
    )
}

export default Header