import React from 'react'

import './main.css';
import './bootstrap.css'
import './animate.css'

import Footer from './Footer'
import AdoutData2 from './AdoutData2';
import Header2 from './Header2';
function Cancellation() {
    return (
        <div className='default-page'>
            <div id="wrapper">
                <div className="page-wrapper">
                    <Header2 />
                    <section className="">
                        <div className='bgimg1'>
                            <div className='about-box'>
                                <h3 className='text-center' style={{ color: 'white' }}> 
                                    Cancellation & Refund
                                </h3>
                            </div>
                        </div>
                    </section>
                    <div className='container'>
                        <div className='row '>
                            <div className='col-sm-12'>
                                <h3>*Cancellation & Refund Policy*</h3>
                                <p>
                                We at The Sundarban prioritize your satisfaction and aim to provide a seamless experience for all our guests. However, we understand that circumstances may arise where you need to cancel or modify your booking. Please review our cancellation and refund policy outlined below:
                                </p>
                                <h4>*1. Cancellation Policy:*</h4>
                                <p>
                                    <b>Cancellation by Customer:</b> Should you need to cancel your booking, please notify us promptly via email or phone. Cancellation requests must be made within the specified time frame outlined below. <br />
                                    <b>Cancellation Fees:</b> The cancellation fee varies based on the timing of your cancellation and the type of booking. Please refer to your booking confirmation or contact us directly for details.<br />
                                    <b>No-shows:</b> Failure to arrive for your scheduled tour without prior notice will result in forfeiture of the booking amount.<br />
                                </p>
                                <h4>*2. Refund Policy:*</h4>
                                <p>
                                    <b>Refunds for Cancellations: </b> Refunds will be issued according to the following schedule: <br />
                                    <b>Cancellations made 7 days or more before the scheduled tour date: </b> [70]% refund of the total booking amount. <br />
                                    <b>Cancellations made between [5] and [7] days before the scheduled tour date: </b> [60]% refund of the total booking amount. <br />
                                    <b>Cancellations made [3] days or less before the scheduled tour date: </b> No refunds will be provided. <br />
                                    <b>Refund Processing: </b> Refunds will be processed using the original payment method within 7-10 business days of receiving the cancellation request. <br />
                                    <b>Non-refundable Fees: </b> Certain fees, such as booking fees or processing fees, may be non-refundable. Please review your booking confirmation for details. <br />
                                </p>
                                <h4>*3. Tour Modifications:*</h4>
                                <p>
                                    <b>Date Changes:</b> If you need to reschedule your tour, please contact us as soon as possible. Date changes are subject to availability and may incur additional fees. <br />
                                    <b>Cancellation by The Sundarban:</b> In rare instances where we must cancel a tour due to unforeseen circumstances, such as inclement weather or safety concerns, a full refund will be provided. <br />
                                </p>
                                <h4>*4. Contact Us:*</h4>
                                <p>
                                For any questions or concerns regarding our cancellation and refund policy, please reach out to our customer service team. We are here to assist you and ensure your experience with The Sundarban exceeds your expectations.
                                </p>
                                <h4>*Note:*</h4>
                                <p>
                                
                                This cancellation and refund policy is subject to change without prior notice. Please review the policy before completing your booking.
                                Special tours, group bookings, or customized itineraries may have different cancellation and refund policies. Please inquire for details specific to your booking.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Cancellation