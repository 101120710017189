import React, { useEffect, useState } from 'react'
import Graylogo from '../img/sundarban/logo.png';
//import Graylogo from '../img/logos/logo-gray.svg';
import logo from '../img/logos/logo.svg';
import image1 from '../img/generic/img-01.jpg'
import { Link, useNavigate } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

function Header2() {
  const [show, setShow] = useState(false)
  const handalClass = () => {
    setShow(!show)
  }
  const [packaheData, setpackaheData] = useState()
  const navigate = useNavigate();
  useEffect(() => {
    fetch('https://nuggettrap.com/api/turist/listpackage.php')
      .then((res) => res.json())
      .then((result) => {
        console.log(result)
        setpackaheData(result.result)
      });

  }, [])
  return (
    <div className='haer-2'>
      <div className='img-div'>
        <Link to='/'>
        <img src={Graylogo} alt='' className='logo-img' />
        </Link>
        <div className='menu-bar'>
          <FaBars color='white' style={{ cursor: 'pointer' }}
            onClick={(e) => { handalClass() }}
          />
        </div>
      </div>
      <div className={"ul-div " + (show ? 'show-mobile' : 'hide-mobile')}>
        <ul>
          <li><Link to='/'>Home</Link></li>
          <li><Link to='/about'>About us</Link></li>
          <li><Link to='/contact-us'>Contact us</Link></li>
          <li><Link to='/package'>Package</Link></li>
          <li><Link to="/login">Login</Link></li>

        </ul>
      </div>
    </div>
  )
}

export default Header2