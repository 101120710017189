import React from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';

function AdminSidebar() {
    return (
        <div>
            <Sidebar style={{height:'560px', background:'#1c3244'}}>
                <Menu>
                    {/* <SubMenu label="Charts">
                        <MenuItem> Pie charts </MenuItem>
                        <MenuItem> Line charts </MenuItem>
                    </SubMenu> */}
                    <MenuItem component={<Link to='/admin/dashboard' />} > Dashboard </MenuItem>
                    <MenuItem component={<Link to='/admin/add-package' /> }> Add package </MenuItem>
                </Menu>
            </Sidebar>;
        </div>
    )
}

export default AdminSidebar