import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
export const createPackage = createAsyncThunk('addPackage',
    async(data)=>{
        const response = await fetch('https://nuggettrap.com/api/turist/add-package.php',{
            method:'POST',
            body:JSON.stringify(data)
        })
        try {
            const result = response.json();
            return result;
        } catch (error) {
            return error;
        }
    }
)
export const fetchPackage = createAsyncThunk('list_package',
    async()=>{
        const res1 = await fetch('https://nuggettrap.com/api/turist/listpackage.php')
        try {
            const resu1 = await res1.json();
            return resu1;
        } catch (error) {
            return error;
        }
    }
)
export const getPackage = createAsyncThunk('getPackage',
    async(id)=>{
        //console.log('https://nuggettrap.com/api/turist/package.php?id='+id)
        const res1 = await fetch('https://nuggettrap.com/api/turist/package.php?id='+id)
        try {
            const resu1 = await res1.json();
            return resu1;
        } catch (error) {
            return error;
        }
    }
)
const initialState = {
    error:null,
    loading:false,

    packageName:'',
    packagePrice:'',
    packageMiniDesc:'',
    packageDesc:'',

    listPackage:[],
    singledata:'',
}
const packageSlice = createSlice({
    name:'add package',
    initialState,
    reducers:{
        setError:(state,action)=>{
            state.error = action.payload
        },
        setLoading:(state,action)=>{
            state.loading = action.payload;
        },
        setName:(state,action)=>{
            state.packageName = action.payload
        },
        setPrice:(state,action)=>{
            state.packagePrice = action.payload
        },
        setMiniDesc:(state,action)=>{
            state.packageMiniDesc = action.payload
        },
        setDesc:(state,action)=>{
            state.packageDesc = action.payload
        },
        setList:(state,action)=>{
            state.listPackage = action.payload;
        }
    },
    extraReducers: (builder)=>{
        builder
        .addCase(createPackage.pending, (state)=>{
            state.loading = true;
        })
        .addCase(createPackage.fulfilled, (state,action)=>{
            state.loading = false;
        })
        .addCase(createPackage.rejected, (state,action)=>{
            state.loading = false;
        })
        .addCase(fetchPackage.pending, (state)=>{

        })
        .addCase(fetchPackage.fulfilled, (state,action)=>{
            console.log('action.payload =')
            console.log(action.payload)
            state.listPackage = action.payload.result
            console.log(state.listPackage)
        })
        .addCase(fetchPackage.rejected, (state,action)=>{
            state.error = action.payload
        })
        .addCase(getPackage.pending, (state)=>{

        })
        .addCase(getPackage.fulfilled, (state,action)=>{
            state.singledata = action.payload.result
            console.log(state.singledata)
        })
        .addCase(getPackage.rejected, (state,action)=>{
            state.error = action.payload;
        })
    }
})

export const { setName,setPrice,setMiniDesc,setDesc,
    setError,setLoading } = packageSlice.actions;
export default packageSlice.reducer;