import React, { useState } from 'react'
import Header from './Header'
import Footer from './Footer'

import banner1 from '../img/banner/img-06.jpg';
import icon1 from '../img/icons/ico01.svg';
import icon2 from '../img/icons/ico02.svg';
import icon3 from '../img/icons/ico03.svg';
import { FaEnvelope, FaFacebook, FaInstagram, FaMapMarker, FaMobile, FaTwitter, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import Header2 from './Header2';
import { Link } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';

import ReCAPTCHA from "react-google-recaptcha";


function Contact() {
    const [contactData, setContactData] = useState({page:'contact'})
    const [submited, setSubmited] = useState(false)
    const handleOpenWhatsApp =() =>{
        const phoneNumber = '9734818702'; // Replace with the desired phone number
        const url = `https://wa.me/${phoneNumber}`;
        window.location.href = url;
        //alert('test')
    }
    const handalSubmit = (e) =>{
        e.preventDefault();
        if(contactData.email){

        }else{
            return alert('PLease enter email!')
        }
       // setContactData((prev)=>({...prev, page:'contact'}));
        fetch('https://nuggettrap.com/api/turist/contact.php',{
            method:'POST',
            body:JSON.stringify(contactData)
        })
        .then((e)=>e.json())
        .then((result)=>{
            setContactData({page:'contact',fname:'',lname:'',email:'',contact:'',message:'' });
            setSubmited(true)
        })
    }
    return (
        <div className='default-page'>
            <div id="wrapper">
                <div className="page-wrapper">
                    <Header2 />
                    <section className="">
                        <div className='bgimg1'>
                            <div className='about-box'>
                                <h3 className='text-center' style={{color:'white'}}> Contact us </h3>
                            </div>
                        </div>
                    </section>

                    <main id="main">
                        <div class="content bg-white">
                            <div class="container">
                                <div className='row'>
                                    <div className='col-sm-6 contact-box'>
                                        <p>
                                            <FaMobile size={30} color='rgb(56 15 3)'className='iconCon'  />
                                            <span> Phone: +91 9734818702</span>
                                        </p>
                                        <p>
                                            <FaEnvelope size={25} color='rgb(56 15 3)'className='iconCon' />
                                            <span> Email: info@thesundarban.com</span>
                                        </p>
                                        <p>
                                            <FaMapMarker size={30} color='rgb(56 15 3)'className='iconCon' />
                                            <span> 
                                            Village: Rangabelia , Post Office: Rangabelia , Dist: South 24 Pgs , Near By Rangabelia ARDS Hospital , Pin - 743370, West Bengal, India.
                                            </span>
                                        </p>
                                        <div>
                                            <ul className='shocial-list'>
                                                <li><FaFacebook size={40} /> </li>
                                                <li><FaYoutube size={40}/> </li>
                                                <li><FaInstagram size={40}/> </li>
                                                <li><FaTwitter size={40}/> </li>
                                                <li 
                                                onClick={handleOpenWhatsApp} 
                                                onTouchStart={handleOpenWhatsApp} 
                                                style={{cursor:'pointer'}}> 
                                                    <FaWhatsapp size={40}/>
                                                 </li>
                                                 
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <form method='post' onSubmit={handalSubmit}>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <h3 className='text-center'>Contact </h3>
                                                <hr style={{borderTop:'1px solid black'}} />
                                                {submited &&
                                                <h4 className='text-success'>
                                                    We will get back to you soon.
                                                </h4>}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <label>First name</label>
                                                <input type='text' className='form-control'
                                                value={contactData.fname}
                                                onChange={(e)=>{setContactData((prev)=>({...prev, fname: e.target.value})) }} />
                                            </div>
                                            <div className='col-sm-6'>
                                                <label> Last name</label>
                                                <input type='text' className='form-control'
                                                onChange={(e)=>{setContactData((prev)=>({...prev, lname:e.target.value}))}} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <label>Email</label>
                                                <input type='text' className='form-control'
                                                onChange={(e)=>{setContactData((prev)=>({...prev, email:e.target.value}))}} />
                                            </div>
                                            <div className='col-sm-6'>
                                                <label>Contact </label>
                                                <input type='text' className='form-control'
                                                onChange={(e)=>{setContactData((prev)=>({...prev, contact:e.target.value}))}} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <label>Message </label>
                                                <textarea className='form-control' rows={6}
                                                onChange={(e)=>{setContactData((prev)=>({...prev, message:e.target.value}))}} 
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <br />
                                            <ReCAPTCHA
                                                sitekey="6LcHwQUpAAAAAG_RZeMJs-oON-mGHGp_2bplJN-O"
                                            />
                                            </div>
                                        </div>
                                        {/* , */}
                                        <div className='row' style={{paddingBottom:'30px'}}>
                                            <div className='col-sm-12'>
                                                <label> </label>
                                                <br />
                                                <input type='submit'
                                                 className='btn btn-block btn-success'
                                                  value='Send message' 
                                                  style={{background:'rgb(56 15 3)'}}
                                                  />
                                            </div>
                                        </div>
                                        </form>
                                    </div>
                                </div>
                                
                                <div className='row'>
                                <div className='col-sm-12'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d947400.926334794!2d87.9296377246782!3d21.923228514373516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a018264f05cd269%3A0xe99cba5218a4be78!2sSundarban%20National%20Park%20(West%20Bengal)!5e0!3m2!1sen!2sin!4v1708933263206!5m2!1sen!2sin" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                                </div>
                            </div>
                        </div>
                        
                    </main>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Contact