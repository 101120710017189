import React from 'react'
import img1 from '../img/sundarban/Sunderban-Delta.png';
import img2 from '../img/sundarban/sundarban-package3.png'
import img3 from '../img/sundarban/tiger2.jpg'

function AdoutData2() {
  return (
    <div className='container'>
        <main id="main">
                        <div className="content-block bg-white content-center">
        <div className='row'>
           
            <div className='col-sm-12'>
                <h2>About</h2>
                <p style={{lineHeight:'31px'}} className='about-text'>
                Welcome to The Sundarban 
                
                your gateway to one of the world's most mesmerizing natural wonders. 
                Nestled in the heart of the Sundarbans, the largest mangrove forest on the planet, 
                our agency is dedicated to providing unparalleled experiences that immerse you in the beauty 
                and magic of this UNESCO World Heritage Site. 

            At The Sundarban, we believe that every journey should be a blend of adventure, education, 
            and conservation. With this ethos in mind, we curate exceptional tours that not only showcase the 
            breathtaking landscapes and diverse wildlife of the Sundarbans but also foster a deep appreciation 
            for its ecological significance.
            <br />
            Our team of experienced guides are passionate about the Sundarbans and are committed to ensuring 
            that your experience is safe, informative, and unforgettable. Whether you're exploring the 
            labyrinthine waterways on a traditional boat, spotting elusive Royal Bengal Tigers in their natural 
            habitat, or witnessing the breathtaking sunset over the mangrove forests, every moment with us is 
            crafted to leave you in awe of nature's wonders. <br />

            In addition to our signature tours, we also offer customizable itineraries to cater to your specific
            interests and preferences. Whether you're a solo traveler, a family looking for an enriching vacation,
            or a group of nature enthusiasts seeking an adventure of a lifetime, we have something for everyone.<br />
    <br/>
            Beyond providing exceptional travel experiences, sustainability lies at the core of everything we do.
             We are committed to responsible tourism practices that minimize our environmental footprint and 
             support the local communities that call the Sundarbans home. By choosing The Sundarban for your 
             next adventure, you're not only embarking on an incredible journey but also contributing to the
              preservation of this unique ecosystem for generations to come. <br />

            Come, join us, and let The Sundarban be your guide to an unforgettable expedition into the heart 
            of one of nature's greatest marvels.

                </p>
            </div>
        </div>
        <div className='row marjin-top'>
            <div className='col-sm-6'>
            <h2 className='text-center'>Our Vision</h2>
                <p  style={{lineHeight:'31px'}} className='about-text'>
                    Our vision is to be recognized as the premier tour agency for exploring the Sundarbans,
                     renowned for our dedication to sustainability, authenticity, and excellence in customer
                    service. We aspire to inspire travelers from around the world to discover the wonders of 
                    the Sundarbans while instilling a profound appreciation for its biodiversity and the 
                    importance of environmental stewardship.
                </p>
            </div>
            <div className='col-sm-6'>
                <img src={img2} alt='test' />
            </div>
        </div>
        <div className='row marjin-top'>
        <div className='col-sm-6'>
                <img src={img3} alt='test' />
            </div>
            <div className='col-sm-6'>
            <h2 className='text-center'>Our Mission</h2>
                <p  style={{lineHeight:'31px'}} className='about-text'>
                At The Sundarban, our mission is to provide immersive and responsible tourism experiences 
                that showcase the unparalleled beauty and ecological significance of the Sundarbans. 
                We are committed to offering exceptional adventures that foster a deep connection with nature,
                 support local communities, and promote conservation efforts to preserve this unique ecosystem
                  for future generations.
                </p>
            </div>
            
        </div>
    </div>
    </main>
    </div>
  )
}

export default AdoutData2