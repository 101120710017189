import React from 'react'
import { Link } from 'react-router-dom'

function AdminNav() {
    return (
        <div>
            <div className='nav-admin'>
                <ul className='nav-ul'>
                    <li><Link>Home</Link></li>
                    <li><Link>list</Link> </li>
                    <li style={{float:'right'}}><Link >Logout</Link></li>

                </ul>
            </div>
        </div>
    )
}

export default AdminNav