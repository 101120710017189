import React from 'react'

import './main.css';
import './bootstrap.css'
import './animate.css'

import Footer from './Footer'
import AdoutData2 from './AdoutData2';
import Header2 from './Header2';
function Privacypolicy() {
    return (
        <div className='default-page'>
            <div id="wrapper">
                <div className="page-wrapper">
                    <Header2 />
                    <section className="">
                        <div className='bgimg1'>
                            <div className='about-box'>
                                <h3 className='text-center' style={{ color: 'white' }}> Privacy Policy</h3>
                            </div>
                        </div>
                    </section>
                    <div className='container'>
                    <div className='row '>
                        <div className='col-sm-12'>
                            <h3>*Privacy Policy*</h3>
                            <p>
                            Thank you for visiting the Sundarban Tour website (the "Site"). Your privacy is important to us, 
                            and we are committed to protecting your personal information. This Privacy Policy outlines the types
                             of personal information we collect, how we use it, and the measures we take to safeguard your information.
                            </p>
                            <h4>*Information We Collect:*</h4>

                            <p>
                            <b>Personal Information:</b> We may collect personal information such as your name, email address, phone number, and payment details when you book a tour or subscribe to our newsletter. <br />
                            <b>Usage Information:</b> We collect information about your interactions with the Site, including your IP address, browser type, pages visited, and referring website. <br />
                            <b>Cookies:</b> We use cookies and similar tracking technologies to enhance your browsing experience and analyze website traffic. You can manage your cookie preferences through your browser settings. <br />
                            </p>

                            <h4>How We Use Your Information:</h4>
                            <p>
                            <b>Provide Services:</b> We use your personal information to process bookings, communicate with you about your tour, and provide customer support.
                            <b>Marketing:</b> With your consent, we may send you promotional emails about our tours, special offers, and upcoming events. You can opt-out of marketing communications at any time.
                            <b>Improve the Site:</b> We analyze usage data to improve the functionality and content of the Site, personalize your experience, and optimize marketing efforts.
                            </p>
                            
                            <h4>Information Sharing</h4>
                            <p>
                            <b> Service Providers:</b> We may share your personal information with third-party service providers who assist us in operating the Site, processing payments, or delivering services.
                            <b> Legal Compliance:</b> We may disclose your information if required by law or in response to valid legal requests, such as court orders or subpoenas.
                            <b> Business Transfers:</b> In the event of a merger, acquisition, or sale of assets, your personal information may be transferred to a successor entity.
                            </p>

                            <h4>Data Security</h4>
                            <p>
                            We implement security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
                            Despite our efforts to safeguard your information, no method of transmission over the Internet or electronic storage is completely secure. Therefore, we cannot guarantee absolute security.
                            </p>
                            
                            <h4>Third-Party Links:</h4>
                            <p>
                            The Site may contain links to third-party websites or services that are not operated or controlled by us. This Privacy Policy does not apply to the practices of third parties, and we are not responsible for their privacy policies or practices.
                            </p>
                            <h4>Children's Privacy</h4>
                            <p>
                            The Site is not intended for individuals under the age of 18. We do not knowingly collect personal information from children without parental consent. If you believe we have inadvertently collected information from a child, please contact us immediately.
                            </p>
                            <h4>Updates to this Privacy Policy</h4>
                            <p>
                            We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised Privacy Policy on the Site. We encourage you to review this Privacy Policy periodically for any updates.
                            </p>
                            <h4>Contact Us</h4>
                            <p>
                            If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at https://thesundarban.com/contact-us
                            </p>
                            <p>
                            By using the Sundarban Tour website, you consent to the collection and use of your personal information as described in this Privacy Policy.
                            </p>
                        </div>
                    </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Privacypolicy