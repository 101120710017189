import React, { useState } from 'react'
import AdminNav from './AdminNav';
import AdminSidebar from './AdminSidebar';
import TestEditor from './TestEditor';
import './App.css'
import { useDispatch, useSelector } from 'react-redux';
import {  setError,setLoading,setMiniDesc,setName,setDesc,setPrice, createPackage } from '../../app/featurs/packageSlice';

function AddPackage() {
    const [file, setFile] = useState(null);
    const dispatch = useDispatch();
    const { error,loading, packageName,packagePrice,packageMiniDesc,packageDesc} = useSelector((state)=>state.package)
    
    const getTextVal = (e) =>{
        dispatch(setDesc(e))
    }
    const handalSave = () =>{
        const formData = new FormData();
        const data ={
            packageName: packageName,
            packagePrice: packagePrice,
            packageMiniDesc: packageMiniDesc,
            packageDesc:packageDesc
        }
        console.log(file)
        formData.append('packageName', packageName);
        formData.append('packagePrice', packagePrice);
        formData.append('packageMiniDesc', packageMiniDesc);
        formData.append('packageDesc', packageDesc);
        //formData.append('inputValue', inputValue);
        formData.append('file', file);

        console.log(formData)
        fetch('https://nuggettrap.com/api/turist/add-package.php',{
            method:'POST',
            body: formData
        })
        .then((res)=>res.json())
        .then((result)=>{
            console.log(result)
        })
        //dispatch(createPackage(formData))
    }
    const handleFileChange = (event) =>{
        const selectedFile = event.target.files[0];
        console.log(selectedFile)
        setFile(selectedFile);
    }
    return (
        <div>
            <AdminNav />
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-3'>
                        <AdminSidebar />
                    </div>
                    <div className='col-sm-7'>
                        <div className="card">
                            <div className='card-header'>
                                <h4>Create package</h4>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-sm-10'>
                                        <form>
                                            <div class="form-group">
                                                <label >Package name</label>
                                                <input type="text" class="form-control"
                                                value={packageName}
                                                onChange={(e)=>{dispatch(setName(e.target.value)) }}
                                                 />
                                            </div>
                                            <div class="form-group">
                                                <label >Package price</label>
                                                <input type="text" class="form-control"
                                                value={packagePrice}
                                                onChange={(e)=>{dispatch(setPrice(e.target.value)) }}
                                                 />
                                            </div>
                                            <div class="form-group">
                                                <label >Mini Description</label>
                                                <input type="text" class="form-control"
                                                value={packageMiniDesc}
                                                onChange={(e)=>{ dispatch(setMiniDesc(e.target.value)) }}
                                                 />
                                            </div>
                                            <div class="form-group">
                                                <label >Image</label>
                                                <input type="file" class="form-control"
                                               onChange={handleFileChange}
                                                 />
                                            </div>
                                            <div class="form-group">
                                                <label >Full Description</label>
                                                <TestEditor testVal={getTextVal} />
                                            </div>
                                            <div className='form-group'>
                                                <br /><br />
                                                <button type='button' className='btn btn-danger btn-block'
                                                onClick={handalSave}>
                                                    Save 
                                                </button>
                                                <br /><br />
                                            </div>

                                        </form>
                                    </div>
                                    <div className='col-sm-2'></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddPackage