import React from 'react'

import './main.css';
import './bootstrap.css'
import './animate.css'

import Footer from './Footer'
import AdoutData2 from './AdoutData2';
import Header2 from './Header2';



function About() {
    return (
        <div className='default-page'>
            <div id="wrapper">
                <div className="page-wrapper">
                    <Header2 />
                    <section className="">
                        <div className='bgimg1'>
                            <div className='about-box'>
                                <h3 className='text-center' style={{ color: 'white' }}> About us</h3>
                            </div>
                        </div>
                    </section>
                
                    <AdoutData2 />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default About