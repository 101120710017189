import React from 'react';
import './App.css'
import AdminNav from './AdminNav';
import AdminSidebar from './AdminSidebar';

function Dashboard() {

  return (
    <div>
      <AdminNav />
      <div className='container-fluid'>
        <div className='row'>
            <div className='col-sm-2'>
                <AdminSidebar />
            </div>
            <div className='col-sm-10'>

            </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard